<template>
  <div>
    <validation-observer v-slot="{ invalid }">
      <v-form id="form-change-password" @submit.prevent="checkChangePassword">
        <v-row no-gutters class="general-form">
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="general-form__col"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Password Lama"
              rules="required"
            >
              <p class="title-14 mb-2">Password Lama</p>
              <v-text-field
                placeholder="Password Lama"
                v-model="oldPassword"
                :error-messages="errors"
                clearable
                :append-icon="showOld ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showOld ? 'text' : 'password'"
                outlined
                dense
                @click:append="showOld = !showOld"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="general-form__col"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Password Baru"
              rules="required"
            >
              <p class="title-14 mb-2">Password Baru</p>
              <v-text-field
                placeholder="Password Baru"
                v-model="newPassword"
                :error-messages="errors"
                clearable
                :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showNew ? 'text' : 'password'"
                outlined
                dense
                @click:append="showNew = !showNew"
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Ulangi Password Baru"
              rules="required|exact"
            >
              <p class="title-14 mb-2">Ulangi Password Baru</p>
              <v-text-field
                placeholder="Ulangi Password Baru"
                v-model="confirmPassword"
                :error-messages="errors"
                clearable
                :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirm ? 'text' : 'password'"
                outlined
                dense
                @click:append="showConfirm = !showConfirm"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <div class="d-flex justify-end">
          <v-btn 
            color="primary"
            :min-width="160"
            type="submit"
            form="form-change-password"
            large
            :disabled="isDisabled || invalid"
          >
            KIRIM
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
    <dialog-info ref="dialogInfo" :info="info" :action="goToMyProfile"/>
  </div>
</template>

<script>
  // Libs
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
  import { required } from 'vee-validate/dist/rules'
  import { get } from 'dot-prop'
  import CryptoJS from 'crypto-js'

  // Utils
  import loadscreen from '@/utils/common/loadScreen'
  import request from '@/utils/request'
  
  // Constants
  import {
    MY_PROFILE
  } from '@/constants/pages'
  import {
    CHANGE_PASSWORD
  } from '@/constants/apis'

  // Components
  import DialogInfo from '@/components/common/DialogInfo'

  export default {
    name: 'FormChangePassword',
    components: {
      ValidationObserver,
      ValidationProvider,
      DialogInfo
    },
    data () {
      return {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        isError: false,
        isShowAllert: false,
        showOld: false,
        showNew: false,
        showConfirm: false
      }
    },
    computed: {
      isDisabled () {
        return !this.oldPassword || !this.newPassword || !this.confirmPassword || this.isError
      },
      info () {
        return {
          title: 'change.pass.dialog.title',
          desc: 'change.pass.dialog.desc',
          btn: 'change.pass.dialog.btn'
        }
      }
    },
    mounted () {
      extend('required', required)
      extend('exact', value => {
        if (value === this.newPassword) {
          return true
        }
        return 'Ulangi password baru berbeda dengan password baru.'
      })
    },
    methods: {
      async checkChangePassword () {
        this.$emit('handleAlert', false, '')
        loadscreen.show()
        const params = {
          old_password: CryptoJS.SHA256(this.oldPassword).toString(),
          new_password: CryptoJS.SHA256(this.newPassword).toString(),
        }
        const res = await request(CHANGE_PASSWORD, params)
        if (res.success) {
          if (this.$refs.dialogInfo) {
            this.$refs.dialogInfo.show()
          }
        } else {
          const message = get(res, 'resultInfo.resultMsg', '')
          this.$emit('handleAlert', true, message)
        }
        loadscreen.hide()
      },
      goToMyProfile () {
        this.$router.push({
          name: MY_PROFILE
        }).catch(() => {})
      }
    },
  }
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
</style>
