<template>
  <div class="change-password-web">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2 class="mb-4">{{ 'forgot.password.form.title' | text }}</h2>
          <v-alert
            dismissible
            type="error"
            v-model="isShowAlert"
          >{{ alertMsg }}</v-alert>
          <form-change-password @handleAlert="handleAlert" />
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import FormChangePassword from '@/apps/web/components/forms/formChangePassword'
  export default {
    name: 'ChangePasswordWeb',
    components: {
      FormChangePassword,
    },
    head: {
      link: [
        { rel: 'canonical', href: 'https://admisi.sttaa.ac.id/profil/ganti-password', id: 'canonical' },
      ]
    },
    data() {
      return {
        isShowAlert: false,
        alertMsg: ''
      }
    },
    methods: {
      handleAlert (isShowAlert, msg) {
        this.isShowAlert = isShowAlert
        this.alertMsg = msg
      }
    }
  }
</script>

<style lang="scss" scoped>
.change-password-web {
  min-height: 500px;
  p {
    margin: 0;
  }
}
</style>